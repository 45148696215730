<!--
 * @Descripttion:
 * @version:
 * @Author: 贾峰
 * @Date: 2020-01-06 14:06:09
 * @LastEditors  : 贾峰
 * @LastEditTime : 2020-01-08 15:57:15
 -->
<template>
    <div class="mainpage" :style="'background: url('+getStorage('ossurl','')+getStorage('imgs',{}).gzhbg+');background-repeat: no-repeat;background-size: 100% 100%;'">
        <img :src="qrcode" alt="">
        <!-- <img :src="'https://saasms-test.oss-cn-beijing.aliyuncs.com/QD1001/Sys_Source/logo/2020-01-07/LOGO20200107120759120129.jfif'" alt=""> -->
    </div>
</template>
<script>
import { getQrcode, familyQrcode } from '@/api/abd/family'
import { getStorage } from '@/lib/util'
import { Toast } from 'mand-mobile'
export default {
  data () {
    return {
      user: {},
      qrcode: ''
    }
  },
  created () {
    this.user = getStorage('u_s', {})
    // getQrcode({}).then(res => { this.qrcode = res.data.data })
    familyQrcode({ userid: this.user.userid, familyno: this.$route.query.familyno, empno: this.$route.query.empno, comid: this.$route.query.comid }).then(res => { this.qrcode = res.data.data; Toast.succeed('建立关系成功') })
  }
}
</script>
<style lang="stylus" scoped>
.mainpage{
    height 100vh
    width 100vw
    // background: url('~@/assets/abd/image/gzhback.jpg') no-repeat;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display flex
    justify-content center
    align-items center
    position relative
    img{
      width 40vw
      height 40vw
      position absolute
      margin-top 3vw
    }
}
</style>
