import { render, staticRenderFns } from "./recommendpage.vue?vue&type=template&id=fbd87a24&scoped=true&"
import script from "./recommendpage.vue?vue&type=script&lang=js&"
export * from "./recommendpage.vue?vue&type=script&lang=js&"
import style0 from "./recommendpage.vue?vue&type=style&index=0&id=fbd87a24&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fbd87a24",
  null
  
)

export default component.exports